<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between test2">
        <div class="test">
          <!-- all page -->
          <button
            @click="OrderStatus = ''"
          >
            <div
              class="me-2 box_content"
              :class="{'me-2 box_content  active': OrderStatus === ''}"
            >
              <p class="mb-0">
                {{ all ? all : 0 }}
              </p>
              <p class="mb-0">
                ทั้งหมด
              </p>
            </div>
          </button>
          <!-- draft page -->
          <!-- <button @click="gotoDraft()">
            <div class="me-2 box_content">
              <p class="mb-0">
                {{ 0 }}
              </p>
              <p class="mb-0">
                ร่าง
              </p>
            </div>
          </button> -->
          <!-- not paid -->
          <button
            @click="OrderStatus = 'PT'"
          >
            <div
              class="me-2 box_content"
              :class="{'me-2 box_content  active': OrderStatus === 'PT'}"
            >
              <p class="mb-0">
                {{ OrderStatusPending ? OrderStatusPending.OrderStatusPendingTransfer : 0 }}
              </p>
              <p class="mb-0">
                รอชำระเงิน
              </p>
            </div>
          </button>
          <!-- paid -->
          <button

            @click="OrderStatus = 'PC'"
          >
            <div
              class="me-2 box_content"
              :class="{'me-2 box_content  active': OrderStatus === 'PC'}"
            >
              <p class="mb-0">
                {{ OrderStatusPending ? OrderStatusPending.OrderStatusPendingConfirm : 0 }}
              </p>
              <p class="mb-0">
                ตรวจสอบ
              </p>
            </div>
          </button>
          <!-- MakeSend -->
          <button

            @click="OrderStatus = 'PP'"
          >
            <div
              class="me-2 box_content"
              :class="{'me-2 box_content  active': OrderStatus === 'PP'}"
            >
              <p class="mb-0">
                {{ OrderStatusPending ? OrderStatusPending.OrderStatusPendingProduct : 0 }}
              </p>
              <p class="mb-0">
                กำลังผลิต
              </p>
            </div>
          </button>
          <button
            @click="OrderStatus = 'PTP'"
          >
            <div
              class="me-2 box_content"
              :class="{'me-2 box_content  active': OrderStatus === 'PTP'}"
            >
              <p class="mb-0">
                {{ OrderStatusPending ? OrderStatusPending.OrderStatusPendingTransport : 0 }}
              </p>
              <p class="mb-0">
                เตรียมจัดส่ง
              </p>
            </div>
          </button>
          <!-- Send -->
          <button
            @click="OrderStatus = 'F'"
          >
            <div
              class="me-2 box_content"
              :class="{'me-2 box_content  active': OrderStatus === 'F'}"
            >
              <p class="mb-0">
                {{ OrderStatusPending ? OrderStatusPending.OrderStatusPendingFinished : 0 }}
              </p>
              <p class="mb-0">
                ส่งแล้ว
              </p>
            </div>
          </button>
          <!-- Expire -->
          <!-- <button @click="gotoExpire()">
            <div class="me-2 box_content">
              <p class="mb-0">
                0
              </p>
              <p class="mb-0">
                หมดอายุ
              </p>
            </div>
          </button> -->
          <!-- Cancel -->
          <button
            @click="OrderStatus = 'C'"
          >
            <div
              class="me-2 box_content"
              :class="{'me-2 box_content  active': OrderStatus === 'C'}"
            >
              <p class="mb-0">
                {{ OrderStatusPending ? OrderStatusPending.OrderStatusPendingCanceled : 0 }}
              </p>
              <p class="mb-0">
                ยกเลิก
              </p>
            </div>
          </button>
        </div>
        <div class="d-flex align-center">
          <v-dialog
            v-if="$store.state.user && $store.state.user.member_id"
            transition="dialog-top-transition"
            width="1200"
          >
            <template v-slot:activator="{ on, attrs }">
              <!--              v-on="on"-->
              <v-btn
                color="primary"
                v-bind="attrs"
                @click="customerOpen()"
              >
                <v-icon class="me-2">
                  {{ icons.mdiCartMinus }}
                </v-icon>
                เปิดบิล
              </v-btn>
            </template>
            <template>
              <v-card>
                <CreateBill />
              </v-card>
            </template>
          </v-dialog>

          <!--          <v-dialog-->
          <!--            v-if="$store.state.user && !$store.state.user.member_id"-->
          <!--            transition="dialog-top-transition"-->
          <!--            width="600"-->
          <!--          >-->
          <!--            <template v-slot:activator="{ on, attrs }">-->
          <!--              <v-btn-->
          <!--                color="primary"-->
          <!--                v-bind="attrs"-->
          <!--                class="me-2 mg-res"-->
          <!--                v-on="on"-->
          <!--              >-->
          <!--                <v-icon class="me-2">-->
          <!--                  {{ icons.mdiCartMinus }}-->
          <!--                </v-icon>-->
          <!--                เปิดบิล-->
          <!--              </v-btn>-->
          <!--            </template>-->
          <!--            <template>-->
          <!--              <v-card>-->
          <!--                <v-card-text class="pb-0 pt-5 bg-header">-->
          <!--                  <div class="d-flex justify-space-between">-->
          <!--                    <p>เปิดบิลใหม่</p>-->
          <!--                    <span-->
          <!--                      v-if="dataOpenUser === false"-->
          <!--                      disabled-->
          <!--                      @click="switchOpenUser('')"-->
          <!--                    >เปิดบิลจากลูกค้ารายใหม่</span>-->
          <!--                    <span-->
          <!--                      v-if="dataOpenUser === true"-->
          <!--                      @click="switchOpenUser('')"-->
          <!--                    >เปิดบิลจากลูกค้าปัจจุบัน</span>-->
          <!--                  </div>-->
          <!--                </v-card-text>-->
          <!--                &lt;!&ndash; Content import &ndash;&gt;-->
          <!--                <OpenBill v-if="dataOpenUser === false" :switchOpenUser="switchOpenUser"-->
          <!--                          :name="name"/>-->
          <!--                <OpenNewUser v-if="dataOpenUser === true" :switchOpenUser="switchOpenUser"/>-->
          <!--                &lt;!&ndash; <v-card-text>-->
          <!--                  <v-btn color="primary" @click="dialog.value = false" class="me-2">เปิดใช้งาน</v-btn>-->
          <!--                  <v-btn color="error" outlined @click="dialog.value = false">เรียนรู้เพิ่มเติม</v-btn>-->
          <!--                </v-card-text> &ndash;&gt;-->
          <!--                &lt;!&ndash; <v-card-actions class="justify-end">-->
          <!--                  <v-btn text @click="dialog.value = false">Close</v-btn>-->
          <!--                </v-card-actions> &ndash;&gt;-->
          <!--              </v-card>-->
          <!--            </template>-->
          <!--          </v-dialog>-->
        </div>
      </div>
      <v-row class="mt-5">
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <v-text-field
            v-model="search"
            :prepend-inner-icon="icons.mdiMagnify"
            label="ค้นหาเลขที่บิล"
            type="text"
            outlined
            dense
            hide-details
            placeholder="ค้นหา"
            @blur="getReport()"
          ></v-text-field>
        </v-col>
        <!--        <v-col-->
        <!--          md="4"-->
        <!--          sm="6"-->
        <!--          cols="12"-->
        <!--        >-->
        <!--          <v-select-->
        <!--            :prepend-inner-icon="icons.mdiTruck"-->
        <!--            dense-->
        <!--            outlined-->
        <!--            hide-details-->
        <!--            label="เฉพาะขนส่ง"-->
        <!--            item-text="transport_type_name"-->
        <!--            item-value="transport_type_id"-->
        <!--            :items="transport"-->
        <!--            disabled-->
        <!--          ></v-select>-->
        <!--        </v-col>-->
        <!--        <v-col-->
        <!--          md="4"-->
        <!--          sm="6"-->
        <!--          cols="12"-->
        <!--        >-->
        <!--          <v-select-->
        <!--            :prepend-inner-icon="icons.mdiPrinterSearch"-->
        <!--            dense-->
        <!--            hide-details-->
        <!--            outlined-->
        <!--            disabled-->
        <!--            label="กรองตามสถานะการพิมพ์"-->
        <!--            :items="statusFilter"-->
        <!--          ></v-select>-->
        <!--        </v-col>-->
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <div>
            <DatePicker
              v-model="date"
              range
              multi-calendars
              value-type="format"
              format="YYYY-MM-DD"
              placeholder="เลือกเฉพาะวันที่"
              style="width: 100%"
              @change="getReport()"
            ></DatePicker>
          </div>
        </v-col>
        <v-col
          v-if="order_selected && order_selected.length > 0"
          md="4"
          sm="6"
          cols="12"
        >
          <v-btn
            color="primary"
            @click="exportAll()"
          >
            ส่งออก
          </v-btn>

          <!--          <v-row>-->
          <!--            <v-col-->
          <!--              md="3"-->
          <!--              cols="12"-->
          <!--            >-->
          <!--              <v-select-->
          <!--                v-model="status"-->
          <!--                dense-->
          <!--                hide-details-->
          <!--                outlined-->
          <!--                item-text="label"-->
          <!--                item-value="value"-->
          <!--                label="เปลี่ยนสถานะ"-->
          <!--                :items="StatusOrder"-->
          <!--                @change="getOrders()"-->
          <!--              ></v-select>-->
          <!--            </v-col>-->
          <!--            <v-col-->
          <!--              md="6"-->
          <!--              cols="12"-->
          <!--            >-->
          <!--              <v-select-->
          <!--                dense-->
          <!--                hide-details-->
          <!--                outlined-->
          <!--                label="พิมพ์"-->
          <!--                :items="print"-->
          <!--                disabled-->
          <!--              ></v-select>-->
          <!--            </v-col>-->
          <!--            <v-col-->
          <!--              md="3"-->
          <!--              cols="12"-->
          <!--            >-->
          <!--              <v-select-->
          <!--                dense-->
          <!--                hide-details-->
          <!--                outlined-->
          <!--                label="ส่งสินค้า"-->
          <!--                :items="transportProduct"-->
          <!--                disabled-->
          <!--              ></v-select>-->
          <!--            </v-col>-->
          <!--            <v-col-->
          <!--              md="3"-->
          <!--              cols="12"-->
          <!--            >-->
          <!--              <v-select-->
          <!--                dense-->
          <!--                hide-details-->
          <!--                outlined-->
          <!--                label="ดาวน์โหลด"-->
          <!--                :items="download"-->
          <!--                disabled-->
          <!--              ></v-select>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
        </v-col>
      </v-row>
      <!-- ตารางสถานะต่าง ๆ -->
      <v-spacer class="mt-5 mb-5"></v-spacer>
      <!--      :search="search"-->
      <!--  -->
      <v-data-table
        :headers="headers"
        :items="reports"
        :server-items-length="total"
        :options.sync="serverOptions"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1"
      >
        <!--        <template v-slot:top>-->
        <!--          <v-toolbar flat>-->
        <!--            &lt;!&ndash; <v-toolbar-title> &ndash;&gt;-->
        <!--            <v-text-field-->
        <!--              v-model="search"-->
        <!--              label="Search"-->
        <!--              single-line-->
        <!--              hide-details-->
        <!--            ></v-text-field>-->
        <!--            &lt;!&ndash; </v-toolbar-title> &ndash;&gt;-->
        <!--            &lt;!&ndash; <v-divider class="mx-4" inset vertical></v-divider> &ndash;&gt;-->
        <!--            <v-spacer></v-spacer>-->

        <!--          </v-toolbar>-->
        <!--        </template>-->
        <template v-slot:item.checkAll="{ item }">
          <v-checkbox
            v-model="order_selected"
            outlined
            dense
            hide-details
            :value="item.order_id"
          >
          </v-checkbox>
        </template>

        <template v-slot:item.index="{ index }">
          {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
        </template>

        <template v-slot:item.importune="{ item }">
          <div
            v-if="item.importune == 'Y'"
            class="danger-badge"
            style="font-size: 13px"
          >
            เร่งด่วน
          </div>
          <div
            v-if="item.PT_type == 'DP'"
            class="success-badge"
            style="font-size: 13px"
          >
            มัดจำ
          </div>
          <div
            v-if="item.status_oth"
            class="warning-badge"
            style="font-size: 11px"
          >
            {{ item.status_oth }}
          </div>

          <router-link
            v-if="item.by_claim_at"
            target="_blank"
            :to="{ name : 'pages-claim', query: { search: item.by_claim_id, claim_id: item.by_claim_id } }"
          >
            (บิลเคลม)
          </router-link>
        </template>

        <template v-slot:item.cost_product="{ item }">
          {{ (parseFloat(item.order_expense || 0.00) - parseFloat(item.transport_expense_price || 0.00)).toFixed(2) }}
        </template>

        <template v-slot:item.order_inv="{ item }">
          <router-link :to="{ name : 'pages-order-detail' ,params : {id : item.order_id} }">
            {{ item.order_inv }}
          </router-link>
        </template>
        <template v-slot:item.order_status="{ item }">
          <span v-if="item.order_status === 'DF'">ดราฟ</span>
          <span
            v-if="item.order_status === 'PT'"
            style="color: #2f2fd3"
          >รอชำระเงิน</span>
          <span
            v-if="item.order_status === 'PC'"
            style="color: #2f2fd3"
          >รอตรวจสอบ</span>

          <span
            v-if="item.order_status === 'PTP'"
            class="warning-badge"
          >เตรียมจัดส่ง</span>
          <span
            v-if="item.order_status === 'PP'"
            class="warning-badge"
          >กำลังผลิต</span>

          <span
            v-if="item.order_status === 'F'"
            class="success-badge"
          >เสร็จสิ้น</span>
          <span
            v-if="item.order_status === 'C'"
            class="danger-badge"
          >ยกเลิก</span>
          <!--      {{item.order_status}}-->
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.order_status === 'PP' && item.PD_emp_id"
            color="primary"
            title="แจ้งเลขแทรค"
            @click="notiTrackOpen(item)"
          >
            แจ้งเลขแทรค
          </v-btn>

          <v-btn
            v-if="item.order_status === 'PTP' && item.PP_tax_no && !item.PTP_date"
            title="กดเพื่อแจ้งเตรียมจัดส่ง"
            @click="updatePrepareToShip(item.order_id)"
          >
            ส่งออกแล้ว
          </v-btn>

          <!--      $('#tax_no_error').hide()-->
          <!--      if(order_status === 'PT'){-->
          <!--      return '';-->
          <!--      }else if (  order_status === 'PC'){-->
          <!--      return '';-->
          <!--      // order_status === 'PP'-->
          <!--      } else if( order_status === 'PTP' && row.PP_tax_no && !row.PTP_date){-->
          <!--      return '<button class="btn btn-square btn-success" onclick="updateStatus(' + row.order_id + ')"  type="button" style="background-color: #4dbd74;border:#4dbd74" title="กดเพื่อแจ้งเตรียมจัดส่ง"><i class="fa fa-truck" aria-hidden="true"></i> &nbsp;เตรียมจัดส่ง &nbsp;</button>';-->
          <!--      } else if( order_status === 'F'){-->
          <!--      return '';-->
          <!--      // order_status === 'PTP' && (row.PP_tax_no === null || row.PP_tax_no === '')-->
          <!--      }else if( order_status === 'PP'){-->

          <!--      return '<a href="#"><button class="btn btn-square btn-success" data-toggle="modal" data-target="#tax" onclick="modalTax()" type="button" style="background-color: #4dbd74;border:#4dbd74" title="แจ้งเลขแทรค"> <i class="fa fa-tag" aria-hidden="true"></i> &nbsp;แจ้งเลขแทรค &nbsp;</button></a>' +-->
          <!--      '<form action="{{url('admin/update_tax').'/'}}'+row.order_id+'" method="get">'+-->
          <!--      '                                    <div class="modal fade" id="tax" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">\n' +-->
          <!--        '                                        <div class="modal-dialog" role="document">\n' +-->
          <!--          '                                            <div class="modal-content">\n' +-->
          <!--            '                                                <div class="modal-header">\n' +-->
          <!--              '                                                    <h5 class="modal-title" id="exampleModalLabel">แจ้งเลขแทรค</h5>\n' +-->
          <!--              '                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n' +-->
          <!--                '                                                        <span aria-hidden="true">&times;</span>\n' +-->
          <!--                '                                                    </button>\n' +-->
          <!--              '                                                </div>\n' +-->
          <!--            '                                                <div class="modal-body">\n' +-->
          <!--              '<span style="color:#4dbd74 ">เลขพัสดุสินค้า</span> <br> <input name="order_inv" disabled="" required id="tax_no" class="form-control" title="" type="text" placeholder="เลขที่" value="'+row.order_inv+'"><br>' +-->
          <!--              '                                                   <input name="PP_tax_no"  id="PP_tax_no"  required class="form-control" title="" type="text" placeholder="กรุณากรอกแจ้งเลขแทรค">   ' +-->
          <!--              // '<span id="tax_no_error"  class="tax_no_error" style="color: #ff4d4d">เลขที่การสั่งซื้อสินค้าซ้ำ</span>' +-->
          <!--              '\n' +-->
          <!--              '                                                </div>\n' +-->
          <!--            '                                                <div class="modal-footer">\n' +-->
          <!--              '                                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">ปิด</button>\n' +-->
          <!--              // id="updateStatusTrackingNumber" onclick="updateStatusTrackingNumber(' + row.order_id + ')"-->
          <!--              '                                                    <button type="submit"  onclick="CheckTrackingNumber()" id="button_update_tax_on" class="btn btn-primary">ยืนยัน</button>\n' +-->
          <!--              '                                                </div>\n' +-->
          <!--            '                                            </div>\n' +-->
          <!--          '                                        </div>\n' +-->
          <!--        '                                    </div>\n'-->
          <!--      '                                    </form>';-->
          <!--      }-->
          <!--      else if(  order_status === 'C'){-->
          <!--      return '';-->
          <!--      }-->

          <!--      <v-text v-if="item.package_status == 'N'">-</v-text>-->
        </template>
        <template v-slot:no-data>
          <!-- <v-btn
            color="primary"
            @click="initialize"
          >
            แจ้งเลขแทรค

          </v-btn> -->
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog
      v-model="dialogTax"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">แจ้งเลขแทรค</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="editedItem.order_inv"
                  type="text"
                  disabled
                  placeholder="กรุณากรอกแจ้งเลขแทรค"
                  label="เลขพัสดุสินค้า"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="editedItem.PP_tax_no"
                  type="text"
                  placeholder="กรุณากรอกแจ้งเลขแทรค"
                  label="กรุณากรอกแจ้งเลขแทรค"
                ></v-text-field>

                <!--                  @change="uploadFile($event)"-->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="notiTrackClose"
          >
            ยกเลิก
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateStatusTrackingNumber"
          >
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiPencil, mdiDelete, mdiCartMinus, mdiMagnify, mdiTruck, mdiPrinterSearch,
}
  from '@mdi/js'

// import config from '../../../config'
import Swal from 'sweetalert2'
import DatePicker from 'vue2-datepicker'
import instance from '../../../axios.service'
import instance_report from '../../../services/report'
import instance_order from '@/services/order'
import OpenBill from '@/views/pages/bill/OpenBill'
import OpenNewUser from '@/views/pages/bill/OpenNewUser'
import CreateBill from '@/views/pages/bill/Process'
import user from '@/services/user'

export default {
  components: {
    DatePicker, OpenBill, OpenNewUser, CreateBill, Swal,
  },
  setup() {
    const transport = ['ไม่มีข้อมูล']
    const statusFilter = ['ยังไม่พิมพ์ใบจ่าหน้า/ใบแพ็คของ', 'ยังไม่พิมพ์ใบเสร็จ/ใบกำกับ']
    const changeStatus = ['ร่าง', 'รอดำเนินการโอน', 'รอการตรวจสอบ', 'อยู่ระหว่างดำเนินการผลิต', 'เตรียมจัดส่ง', 'เสร็จสิ้น', 'ยกเลิก']
    const print = [
      'จ่าหน้าผู้รับ',
      'จ่าหน้าผู้รับ และผู้ส่ง',
      'ใบกำกับภาษี / ใบเสร็จรับเงิน',
      'ใบแพ๊กของ',
      'ใบแพ็กของพร้อมจ่าหน้า (ใหญ่)',
      'ใบแพ็กของพร้อมจ่าหน้า (เล็ก)',
      'ตั้งค่าหน้ากระดาษ',
    ]
    const transportProduct = [
      'นัดรับพรุ่งนี้เวลา 09:00 - 12:00 น.',
      'นัดรับพรุ่งนี้เวลา 12:00 - 15:00 น.',
      'นัดรับพรุ่งนี้เวลา 15:00 - 18:00 น.',
      'นัดรับวันจันทร์ ที่ 1 09:00 - 12:00 น.',
      'นัดรับวันจันทร์ ที่ 1 12:00 - 15:00 น.',
      'นัดรับวันจันทร์ ที่ 1 15:00 - 18:00 น.',
      'นัดรับวันอังคาร ที่ 2 09:00 - 12:00 น.',
      'นัดรับวันอังคาร ที่ 2 12:00 - 15:00 น.',
      'นัดรับวันอังคาร ที่ 2 15:00 - 18:00 น.',
      'นัดรับวันพุธ ที่ 3 09:00 - 12:00 น.',
      'นัดรับวันพุธ ที่ 3 12:00 - 15:00 น.',
      'นัดรับวันพุธ ที่ 3 15:00 - 18:00 น.',
      'นัดรับวันพฤหัสบดี ที่ 4 09:00 - 12:00 น.',
      'นัดรับวันพฤหัสบดี ที่ 4 12:00 - 15:00 น.',
      'นัดรับวันพฤหัสบดี ที่ 4 15:00 - 18:00 น.',
    ]
    const download = ['ดาวโหลด (CSV)']

    return {
      icons: {
        mdiCartMinus,
        mdiMagnify,
        mdiTruck,
        mdiPrinterSearch,
        mdiPencil,
        mdiDelete,
      },
      transport,
      statusFilter,
      changeStatus,
      print,
      transportProduct,
      download,
    }
  },
  data: () => ({
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    order_selected: [],
    reports: [],
    dialog: false,
    dialogDelete: false,
    total: 0,
    all: 0,
    name: '',
    status: '',
    OrderStatus: '',
    OrderStatusPending: {},
    StatusOrder: [],
    date: [],
    dialogTax: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      { text: '#', value: 'checkAll' },

      // {
      //   text: 'ลำดับ',
      //   value: 'index', sortable: false
      // },
      { text: 'เร่งด่วน', value: 'importune' },
      { text: 'ชื่อร้าน', value: 'member.member_shop_name', sortable: false },
      { text: 'เลขใบสั่งซื้อ', value: 'order_inv' },
      { text: 'วันที่สั่งซื้อ', value: 'order_date_time' },
      { text: 'จำนวน', value: 'count_order' },
      { text: 'ค่าสินค้า', value: 'cost_product' },
      { text: 'ค่าส่ง', value: 'transport_expense_price' },
      { text: 'ส่วนลด', value: 'order_price_discount' },
      { text: 'รวมสุทธิ', value: 'order_expense' },
      { text: 'สถานะ', value: 'order_status' },
      { text: 'เครื่องมือ', value: 'actions', sortable: false },

      // { text: 'แก้ไขข้อมูล', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      order_inv: '',
      PP_tax_no: '',
    },
    defaultItem: {
      order_inv: '',
      PP_tax_no: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getReport()
      },
    },
    search: {
      handler() {
        this.getReport()
      },
    },

    // date(val) {
    //   this.dateFormatted = this.formatDate(this.date_payment)
    // },
    status: {
      handler() {
        this.getReport()
      },
    },
    OrderStatus: {
      handler() {
        this.filterOrder()
      },
    },

    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    // this.search = this.$route.query.search ? this.$route.query.search : ''
    const date = new Date()
    const date2 = new Date()
    let day = date.getDate()
    const day2 = date.getDate()
    const last_day = this.lastday(date2.getFullYear(), date2.getMonth() - 1)
    const last_month = date.getMonth()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    if (day > last_day) {
      day = last_day
    }

    // this.date = `${year}-${String(month).padStart(2, '0')}-${day}`;

    this.date.push(`${year}-${String(last_month).padStart(2, '0')}-${day}`)
    this.date.push(`${year}-${String(month).padStart(2, '0')}-${day2}`)

    this.getReport()
    this.getOrderReport()
    this.getStatusOrder()
  },

  methods: {

    lastday(y, m) {
      // Create a new Date object representing the last day of the specified month
      // By passing m + 1 as the month parameter and 0 as the day parameter, it represents the last day of the specified month
      return new Date(y, m + 1, 0).getDate()
    },

    // editItem(item) {
    //   this.editedIndex = this.reports.indexOf(item)
    //   this.editedItem = { ...item }
    //   this.dialog = true
    // },
    //
    // deleteItem(item) {
    //   this.editedIndex = this.reports.indexOf(item)
    //   this.editedItem = { ...item }
    //   this.dialogDelete = true
    // },
    notiTrackOpen(item) {
      this.notiTrackClose()
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = { ...item }
      this.dialogTax = true
    },
    notiTrackClose() {
      this.dialogTax = false

      // this.$nextTick(() => {
      this.editedItem = { ...this.defaultItem }
      this.editedIndex = -1

      // })
    },

    getOrderReport(data) {
      this.$store.state.isLoading = true
      if (!data) {
        data = {
          search: this.search,
          date: this.date,
          size: this.serverOptions.itemsPerPage,
          status: this.$route.query.search ? this.$route.query.search : this.status,
        }
      }
      instance_order
        .getOrderReport(data)
        .then(res => {
          if (res.status === 200) {
            this.OrderStatusPending = res.data.data
          }

          // removrToken
          user.removrToken(res)
          this.$store.state.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.$store.state.isLoading = false
        })
    },
    getStatusOrder() {
      instance_order
        .getStatusOrder()
        .then(res => {
          if (res.status === 200) {
            this.StatusOrder = res.data.data
            this.StatusOrder.splice(0, -1, {
              label: 'ทั้งหมด',
              value: '',
            })
          }
          user.removrToken(res)
        })
        .catch(error => {
          console.log(error)
        })
    },
    notiTrackContirm() {
      Swal.fire({
        title: 'เตรียมการจัดส่ง ใช่หรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      })
        .then(willDelete => {
          if (willDelete.isConfirmed) {
            const data = {
              order_id: this.order_id,
            }
            this.$store.state.data = true
            instance_order.updateTax(data).then(res => {
              this.$store.state.data = false
              location.reload()
            }).catch(err => {
              this.$store.state.data = false
              Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด !!',
                timer: 1500,
                text: err.data.message,
              })
            })
          }
        })
      this.dialogTrack = false
    },
    updatePrepareToShip(order_id) {
      Swal.fire({
        title: 'เตรียมการจัดส่ง ใช่หรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      })
        .then(willDelete => {
          if (willDelete.isConfirmed) {
            const data = {
              order_id,
            }
            this.$store.state.data = true
            instance_order.prepareToShip(data).then(res => {
              this.$store.state.data = false
              location.reload()
            }).catch(err => {
              this.$store.state.data = false
              Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด !!',
                timer: 1500,
                text: err.data.message,
              })
            })
          }
        })
    },

    updateStatus(id) {
      // $('#updatePrepareToShip').attr('action', "{{ url('admin/updatePrepareToShip/:id') }}".replace(':id', id))
      //   .submit()
      Swal.fire({
        title: 'เตรียมการจัดส่ง ใช่หรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      })
        .then(willDelete => {
          if (willDelete.isConfirmed) {
            const data = {
              order_id: this.order_id,
            }
            this.$store.state.data = true
            instance_order.prepareToShip(data).then(res => {
              this.$store.state.data = false
              location.reload()
            }).catch(err => {
              this.$store.state.data = false
              Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด !!',
                timer: 1500,
                text: err.data.message,
              })
            })
          }
        })
    },
    updateStatusTrackingNumber() {
      const data = {
        order_id: this.editedItem.order_id,
        PP_tax_no: this.editedItem.PP_tax_no,
      }
      this.$store.state.data = true
      instance_order.orderTax(data).then(res => {
        if (res.data.success) {
          this.notiTrackClose()

          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
          this.getReport()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: res.data.message,
          })
        }
        this.$store.state.data = false

        // location.reload()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.data.message,
        })
        this.$store.state.data = false
      })
    },
    filterOrder() {
      this.$store.state.isLoading = true
      const data = {
        search: this.search ? this.search : '',
        date: this.date ? this.date : '',
        status: this.OrderStatus ? this.OrderStatus : '',
        status2: this.$route.query.search ? this.$route.query.search : '',
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        sortBy: this.sortBy ?? '',
        sortDesc: this.sortDesc ?? '',
      }

      instance_order
        .getOder(data)
        .then(res => {
          if (res.status === 200) {
            this.reports = res.data.data.data
            this.total = res.data.data.total
            this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
            this.serverOptions.page = res.data.data.current_page
          }

          // removrToken
          user.removrToken(res)
          this.$store.state.isLoading = false
        })
        .catch(error => {
          this.$store.state.isLoading = false
        })
    },
    exportAll() {
      Swal.fire({
        title: 'เตรียมการจัดส่งทั้งหมด ใช่หรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      })
        .then(willDelete => {
          if (willDelete.isConfirmed) {
            this.$store.state.data = true
            instance_order.prepareToShipAll(this.order_selected).then(res => {
              this.$store.state.data = false
              location.reload()
            }).catch(err => {
              this.$store.state.data = false
              Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด !!',
                timer: 1500,
                text: err.data.message,
              })
            })
          }
        })
    },
    getReport() {
      this.$store.state.isLoading = true
      const data = {
        search: this.search,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        date: this.date ? this.date : this.$route.query.date,
        status: this.$route.query.search ? this.$route.query.search : this.status,
        sortBy: this.sortBy ?? '',
        sortDesc: this.sortDesc ?? '',
      }

      // status:this.$route.query.status ? this.$route.query.status : '',
      // date: this.$route.query.date ? this.$route.query.date : '',

      instance_report.getReportBookingOrder(data).then(res => {
        this.reports = res.data.data.data
        this.total = res.data.data.total
        this.all = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}

.box_content {
  width: 5rem;
  height: 5rem;
  text-align: center;
  background-color: #f4f5f9;
  display: grid;
  align-items: center;
  padding: 10px 0;
  color: #8e8a98;
  font-weight: 600;
  font-size: 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.box_content.active {
  background-color: #8dbc89;
  color: #ffffff;
}

.bg-header {
  background-color: #8dbc89;
}

.bg-header p {
  color: #f4f5f9;
  font-weight: 600;
  font-size: 16px;
}

.bg-header span {
  color: #fff8ff;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  text-decoration: underline;
}

.checkItem {
  width: 15px;
  height: 15px;
  margin-right: 1rem;
}

.picker-res {
  width: 100% !important;
}

@media screen and (max-width: 600px) {
  .test {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .test2 {
    display: grid !important;
  }

  .mg-res {
    margin-top: 1.5rem;
    transform: translateX(-40%);
    left: 50%;
  }

  /* .picker-res {
    width: 100% !important;
  } */
}
</style>
